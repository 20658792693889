<template>
 <div>
  <svg id="Laag_1" style="enable-background:new 0 0 183 183;" version="1.1" viewBox="0 0 183 183" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
   <defs>
    <linearGradient gradientTransform="matrix(1 0 0 -1 0 184)" gradientUnits="userSpaceOnUse" id="icon-menu-kids_00000066505100191794785070000007387914534453405109_" x1="152.3383" x2="40.0417" y1="143.5299" y2="49.2976">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <rect :fill="background" height="183" width="183"/>
   <path d="M57.4,78.8  h-8.5V49.1h-8.5v29.7h-8.5V49.1h-8.5v29.7c0,8.9,7,16.3,15.9,16.8v38.3H50V95.6c8.9-0.5,15.9-7.9,15.9-16.8V49.1h-8.5V78.8z   M138.4,66.1V100H149v33.9h10.6V49.1C147.8,49.1,138.3,58.6,138.4,66.1L138.4,66.1z M119.7,100.4c-3.2-7.4-9.8-12.7-17.7-14.2  l3.5-5.6l-3.5-2.2l-4.6,7.4c-9.6,0.1-18.2,5.8-22,14.7c-5.2,0-9.4,4.2-9.4,9.4c0,5.2,4.2,9.4,9.4,9.4c0,0,0,0,0,0l0,0  c5.2,12.2,19.4,17.9,31.6,12.7c5.7-2.4,10.3-7,12.7-12.7c5.2,0,9.4-4.2,9.4-9.4C129.1,104.6,124.9,100.4,119.7,100.4L119.7,100.4z   M123.2,113.5c-1,1-2.3,1.5-3.6,1.6h-2.7l-1.1,2.5c-4.3,10.1-16,14.8-26.1,10.5c-4.8-2-8.5-5.8-10.5-10.5l-1.1-2.5h-2.7  c-2.9,0.1-5.3-2.1-5.5-5s2.1-5.3,5-5.5c0.2,0,0.3,0,0.5,0h2.7l1.1-2.5c4.3-10.1,16-14.8,26.1-10.5c4.8,2,8.5,5.8,10.5,10.5l1.1,2.5  h2.7c2.9,0.1,5.2,2.4,5.1,5.3C124.7,111.2,124.2,112.5,123.2,113.5z M86,104.6h5.2v5.2h-5.3L86,104.6z M103.8,104.6h5.2v5.2h-5.2  L103.8,104.6z M97.5,124.5c5.8,0,10.4-4.7,10.4-10.5H87C87,119.8,91.7,124.5,97.5,124.5L97.5,124.5L97.5,124.5z" id="icon-menu-kids" style="fill:url(#icon-menu-kids_00000066505100191794785070000007387914534453405109_);"/>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>